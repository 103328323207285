import React, { useState, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { getUserTokenData } from "../services/user";
import Header from "../widget/Header";
import Navbar from "../widget/Navbar";
import Footer from "../widget/Footer";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReactDatatable from "@mkikets/react-datatable";
import { InputValid } from "../validations/InputValid";
import { ToastContainer, toast } from "react-toastify";

export const UserTokenDetails = () => {
  const [record, setRecord] = useState([]);

  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email address",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "mobile_number",
      text: "Phone number",
      className: "id",
      align: "left",
      sortable: true,
    },

    {
      key: "equalinusdt",
      text: "Total amount bought in USD",
      className: "qty",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.equalinusdt ? Number(record.equalinusdt).toFixed(4) : 0} </>;
      },
    },
    {
      key: "totaltransaction",
      text: "Number of transactions",
      className: "qty",
      align: "left",
      sortable: true,
    },
    {
      key: "total_token_amount",
      text: "Total Tokens bought",
      className: "qty",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.total_token_amount ? Number(record.total_token_amount).toFixed(4) : 0} </>;
      },
    },
    {
      key: "total_bonus",
      text: "Total bonus",
      className: "qty",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.total_bonus ? record.total_bonus : 0} </>;
      },
    },
    {
      key: "total_referred_token_amount",
      text: "Referral token",
      className: "qty",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>{record.total_referred_token_amount ? Number(record.total_referred_token_amount).toFixed(4) : 0} </>;
      },
    },

    // {
    //   key: "action",
    //   text: "Action",
    //   className: "Action",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <>
    //         <button
    //           className="btn btn-primary"
    //           onClick={() => handleShow(record)}
    //           title="Bouns"
    //         >
    //           Add
    //         </button>
    //       </>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  useEffect(() => {
    getTopRankReferral();
  }, []);

  const pageChange = (pageData) => {};

  const getTopRankReferral = async () => {
    const config = localStorage.getItem("jwtToken");
    const resp = await getUserTokenData(config);

    if (resp.status) {
      console.log(resp.data, "resp.data");
      setRecord(resp.data);
    }
  };
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>User Token History</b>
              </h2>
            </div>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};
