import * as opsService from "./Ops";

import { BaseUrl } from "../Constent/baseUrl";

const loginAdmin = async (data) => {
  let result = await opsService.postdata(BaseUrl + "/login-admin", data);
  return result;
};
const toprankReward = async (data, token) => {
  let result = await opsService.postdata(
    BaseUrl + "/top-rank-reward",
    data,
    token
  );
  return result;
};
const getTopReferrals = async (data) => {
  let result = await opsService.getData(
    BaseUrl + "/get-top-referral-data",
    data
  );
  return result;
};
const getUserTokenData = async (token) => {
  let result = await opsService.getData(BaseUrl + "/get-user-token-data", token);
  return result;
};
const updateBonusData = async (token) => {
  let result = await opsService.getData(BaseUrl + "/update-bonus-cron", token);
  return result;
};




export { loginAdmin, toprankReward, getTopReferrals, getUserTokenData,updateBonusData };
