import React, { useState, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { getTopReferrals, toprankReward } from "../services/user";
import Header from "../widget/Header";
import Navbar from "../widget/Navbar";
import Footer from "../widget/Footer";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ReactDatatable from "@mkikets/react-datatable";
import { InputValid } from "../validations/InputValid";
import { ToastContainer, toast } from "react-toastify";

export const TopRankUser = () => {
  const [record, setRecord] = useState([]);

  const [bonus, setbonus] = useState("");
  const [bonusErr, setBonusErr] = useState("");
  const [walletAddress, setWalletAddress] = useState("");
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const columns = [
    {
      key: "Sr No.",
      text: "Sr. No.",
      className: "sr_no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "rank",
      text: "Rank",
      className: "cust_name",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },

    {
      key: "wallet_address",
      text: "Wallet Address",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "no_of_referral",
      text: "No of Referrals",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "total_referral_amt",
      text: "Total Referral",
      className: "id",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <>${Number(record?.total_referral_amt).toFixed(2)}</>;
      },
    },

    {
      key: "prize",
      text: "Prize",
      className: "qty",
      align: "left",
      sortable: true,
    },

    // {
    //   key: "action",
    //   text: "Action",
    //   className: "Action",
    //   align: "left",
    //   sortable: true,
    //   cell: (record) => {
    //     return (
    //       <>
    //         <button
    //           className="btn btn-primary"
    //           onClick={() => handleShow(record)}
    //           title="Bouns"
    //         >
    //           Add
    //         </button>
    //       </>
    //     );
    //   },
    // },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Download",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Search in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  useEffect(() => {
    getTopRankReferral();
  }, []);

  const handleShow = (record) => {
    setWalletAddress(record.wallet_address);
    setId(record.id);
    setShow(true);
  };
  const pageChange = (pageData) => {};

  const onsubmit = async (e) => {
    const checkQuestion = InputValid("bonus", bonus);
    if (checkQuestion) {
      setBonusErr(checkQuestion);
      return false;
    }

    let data = {
      wallet_address: walletAddress,
      reward: bonus,
      id,
    };
    const config = localStorage.getItem("jwtToken");

    const result = await toprankReward(data, config);
    if (result.status) {
      toast.dismiss();
      toast.success(result.message);
      handleClose();
      getTopRankReferral();
    } else {
      toast.dismiss();
      toast.error(result.message);
    }
  };
  const handlechange = (e) => {
    let { name, value } = e.target;
    if (name === "bonus") {
      setbonus(value);
      const err = InputValid(name, value);
      setBonusErr(err);
    }
  };
  const handleClose = () => {
    setbonus("");
    // setBonusErr("");
    // setUsdt("");

    setShow(false);
  };
  const getTopRankReferral = async () => {
    const resp = await getTopReferrals();

    if (resp.status) {
      setRecord(resp.data);
    }
  };
  return (
    <>
      {" "}
      <div id="layout-wrapper">
        <Header />
        <Navbar />
        <div className="main-content">
          <div className="page-content">
            <div className="section-heading d-flex justify-content-between">
              <h2>
                <b>Top Rank User List</b>
              </h2>
              {/* <div>
                {" "}
                <w3m-button balance="hide" />
              </div> */}
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                {" "}
                <Modal.Title style={{ color: "black" }}>Add Reward</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label> Reward </Form.Label>
                    <Form.Control
                      name="bonus"
                      onChange={handlechange}
                      type="text"
                      // onKeyPress={(event) => {
                      //   if (!/[0-9]/.test(event.key)) {
                      //     event.preventDefault();
                      //   }
                      // }}
                      value={bonus}
                    ></Form.Control>
                    <span style={{ color: "red" }}>{bonusErr}</span>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={onsubmit}>
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
            <div className="row">
              <div className="col-xxl-12">
                <div className="product-list-outer table-responsive">
                  <ReactDatatable
                    config={config}
                    records={record}
                    columns={columns}
                    onPageChange={pageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <ToastContainer
            limit={1}
            autoClose={3000}
            pauseOnHover={false}
            pauseOnFocusLoss={false}
            theme={"dark"}
          />
        </div>
      </div>
    </>
  );
};
